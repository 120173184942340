<template>
	<v-container class="nav-footer" fluid>
		<v-row>
			<v-col
				:class="{
					active: isActive('launch')
				}"
				class="btn"
				@click="navigate({ name: 'launch' }, 'up')"
			>
				<v-icon style="font-size: 18px; color: inherit"
					>fas fa-search</v-icon
				>
				<p class="mb-0" style="color: inherit">Discover</p>
			</v-col>
			<v-col
				:class="{
					active: isActive('login')
				}"
				class="btn"
				@click="navigate({ name: 'login' }, 'up')"
			>
				<v-icon style="font-size: 18px">fas fa-user-circle</v-icon>
				<p class="mb-0">Login</p>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
export default {
	name: 'nav-footer',

	props: [],
	data: () => ({}),
	methods: {
		isActive(name) {
			return name === this.$router.history.current.name
		}
	}
}
</script>

<style lang="scss">
@import '@roundupapp/component-library/src/styles/index';

.nav-footer {
	box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);
	max-width: 480px !important;
	width: 100%;
	position: fixed;
	left: 50%;
	bottom: 0;
	transform: translateX(-50%);
	margin: 0 auto;

	.active {
		color: $roundup-primary !important;
	}

	.btn {
		cursor: pointer;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}
}
</style>
