<template>
	<v-container
		class="nav-header"
		fluid
		:style="{
			borderBottom: divider ? '1px solid #dddee3 !important' : ''
		}"
	>
		<v-row style="height: inherit">
			<v-col class="px-5 " style="height: inherit; min-height: 60px;">
				<a
					@click="handleClick"
					style="display: flex; flex-direction: row; align-items: center"
				>
					<v-icon
						style="font-size: 14px; color: black"
						class="nav-header-icon"
					>
						fas fa-angle-left
					</v-icon>
					<span class="nav-header-label">Back</span>
				</a>
				<span class="ml-8 nav-header-title"><slot></slot></span>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
export default {
	name: 'nav-header',

	props: {
		label: {
			type: String,
			default: null
		},
		routerPath: {
			type: String,
			default: null
		},
		divider: {
			type: Boolean,
			default: false
		}
	},
	data: () => ({}),
	methods: {
		isActive(name) {
			return name === this.$router.history.current.name
		},
		handleClick() {
			if (!this.routerPath) {
				this.$router.go(-1)
			} else {
				this.$router.push({ name: this.routerPath })
			}
		}
	}
}
</script>

<style lang="scss">
@import '@roundupapp/component-library/src/styles/index';

.nav-header {
	background-color: $white;
	width: 100%;
	min-height: 60px;
	&-icon,
	&-label {
		color: $roundup-primary !important;
	}
	&-label {
		margin: 0 0 0 12px !important;
		font-weight: 600;
	}
	&-title {
		font-size: 24px !important;
		color: $roundup-slate !important;
		font-weight: 600;
	}

	.col {
		display: flex;
	}
}
</style>
