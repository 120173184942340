<template>
	<div
		class="guest-template pa-0"
		style="display: flex; flex-direction: column; align-items: center"
	>
		<nav-header
			:divider="divider"
			:routerPath="routerPath"
			v-if="showNavHeader"
			>{{ navHeaderTitle }}</nav-header
		>
		<slot></slot>
		<nav-footer v-if="showNavFooter" />
	</div>
</template>

<script>
import NavFooter from '../ui/NavFooter.vue'
import NavHeader from '../ui/NavHeader.vue'
export default {
	props: {
		showNavFooter: {
			type: Boolean,
			default: true
		},
		showNavHeader: {
			type: Boolean,
			default: false
		},
		navHeaderTitle: {
			type: String,
			default: ''
		},
		routerPath: {
			type: String,
			default: null
		},
		divider: {
			type: Boolean,
			default: false
		}
	},
	components: { NavFooter, NavHeader },
	name: 'guestTemplate'
}
</script>
